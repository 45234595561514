import { Schedule } from './../atividades/model/schedule.model';
import { PeriodService } from './../atividades/service/period.service';
import { ActivityService } from './../atividades/service/activity.service';
import { ProfileSchoolPackageService } from './../shared/service/profileSchoolPackage.service';
import { AutenticacaoService } from './../login/service/autenticacao.service';
import { Router } from '@angular/router';
import { Component } from "@angular/core";
import { finalize, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Category } from '../shared/model/category.model';
import { CategoryService } from '../shared/service/category.service';
import { forkJoin } from 'rxjs';
import { faClipboard, faClipboardCheck, faClipboardList, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Activity } from '../atividades/model/activity.model';
import { ProfileSchoolPackage } from '../shared/model/profileSchoolPackage.model';
import { Period } from '../atividades/model/period.model';

@Component({
  selector: 'app-turma',
  templateUrl: './turma.component.html',
  styleUrls: ['./turma.component.css']
})
export class TurmaComponent {
  periods: Period[] = [];
  groupedActivities!: Activity[][];

  schoolPackages: any[] = [];
  filteredSchoolPackages: any[] = [];
  schoolPackageId: number = 0;

  schools: any[] = [];
  schoolId: number = 0;

  categories!: Category[];
  chCategories!: Category[];
  filteredCategories!: Category[];
  profiles: ProfileSchoolPackage[] = [];
  isLoading = true;

  faClipboardCheck = faClipboardCheck;
  faClipboard = faClipboard;
  faExclamationTriangle = faExclamationTriangle;
  faClipboardList = faClipboardList;

  groupedTasks: any[][] = [];
  averages: any[] = [];

  currentWeek: number = 0;

  constructor(
    private router: Router,
    private autenticacaoService: AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService,
    private categoryService: CategoryService,
    private activityService: ActivityService,
    private periodService: PeriodService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.autenticacaoService
      .getLoggedUser()
      .pipe(
        switchMap((u) => {
          if (u?.role === 'Students') {
            this.router.navigate(['/mural']);
          }
          return forkJoin([
            this.profileSchoolPackageService.findPerProfileId(u?.profileId as number),
            this.categoryService.findAll('Hub'),
            this.categoryService.findAll('CH')
          ]);
        })
      )
      .subscribe((result) => {
        this.schoolPackages = [...new Map(result[0]
          .filter(x => x.schoolPackage.name !== 'Galera Cult')
          .map(x => x.schoolPackage)
          .map(item => [item['id'], item])).values()];

        this.filteredSchoolPackages = _.sortBy(this.schoolPackages, x => parseInt(x.name));

        this.schools = _.sortBy(
          [...new Map(result[0].map(x => x.schoolPackage.school).map(item => [item['id'], item])).values()],
          (x) => parseInt(x.name));

        this.categories = result[1];
        this.chCategories = result[2];
        this.isLoading = false;
      });
  }

  selectSchool(): void {
    if (this.schoolId == 0) {
      this.filteredSchoolPackages =
        _.sortBy(this.schoolPackages, x => parseInt(x.name));
    } else {
      this.filteredSchoolPackages = _.sortBy(this.schoolPackages
        .filter(x => x.school.id == this.schoolId),
        (x) => parseInt(x.name));
    }
  }

  selectSchoolPackage(): void {
    this.isLoading = true;
    this.groupedTasks = [];
    this.averages = [];

    this.periodService
        .findPerSchoolPackageId(this.schoolPackageId)
        .pipe( finalize( () => this.isLoading = false ) )
        .subscribe( (result) => {
          this.periods = result.periods;
          const schedules = (_.flatten(_.pluck(result.periods, 'schedules')));
          const activities = (_.uniq(_.flatten(_.pluck(schedules, 'activities'))));

          this.filteredCategories =  this.categories.filter(x => activities.filter(y => y.categoryId === x.id).length > 0);

          if (result.profiles[0].schoolPackage.packageId < 10) {
            this.filteredCategories.push({id: 9999, name: "Matemática 2", description: "mat2", image: "", imageAlt: "", shortName: "Mat 2" });
          }

          this.filteredCategories = _.sortBy(this.filteredCategories, (x) => x.name);
          this.filteredCategories = this.filteredCategories.concat(this.chCategories.filter(x => activities.filter(y => y.categoryId === x.id).length > 0));


          this.profiles = result.profiles;

          this.currentWeek = (moment(new Date()).week());
        });
  }

  getObjTarefa(categoryId: number, schedule: Schedule): boolean {
    if(!schedule.activities) {
     return false;
    }
    let cat = (categoryId === 9999) ?
      schedule.activities?.filter(x => x.categoryId === 6) :
      schedule.activities?.filter(x => x.categoryId === categoryId);

    if (cat && cat.length > 0 && cat[0].packageId < 10 && (categoryId === 6 || categoryId === 9999) ) {
      const numeracoes = _.uniq(cat.map((x) => parseInt(x.title.replace( /^\D+/g, '')))).sort( (a, b) => a - b);

      if(categoryId === 6) {
        const mat_1_arr = numeracoes.filter( (el, idx) =>
          ( schedule.name.includes('10') && el >= 10 && el < 11 )  ||
          ( idx === 0 && el < 10 )
        );
        cat = cat.filter(x => mat_1_arr.includes(parseInt(x.title.replace(/^\D+/g, ''))));


        //cat = cat.filter((x) => parseInt(x.title.replace( /^\D+/g, '')) ===  numeracoes[0]);
      } else {
        const mat_2_arr = numeracoes.filter( (el) =>
          schedule.name.includes('10') ? el >= 20 : el >= 10
        );

        //const mat_2_arr = numeracoes.filter( (_, idx) => idx !== 0);
        cat = cat.filter((x) => mat_2_arr.includes(parseInt(x.title.replace(/^\D+/g, ''))));
        //cat = cat.filter((x) => parseInt(x.title.replace( /^\D+/g, '')) ===  numeracoes[numeracoes.length - 1]);
      }

    }

    if (!cat || cat.length === 0) {
      return false;
    }

    if(!this.groupedTasks[categoryId]) {
      this.groupedTasks[categoryId] = [];
      this.averages = [];
    }

    if (cat.filter(x => x.performed.length !== this.profiles.length && this.isPastWeek(x.dueDate)).length > 0) {
      const c = _.min(cat, (x) => x.performed.length) as Activity;

      this.groupedTasks[categoryId][schedule.id] = ( c.performed.length / (this.profiles.length) ) * 100;
      return true;
    }
    if (cat.filter(x => x.performed.length !== this.profiles.length && (this.isCurrentWeek(x.dueDate) || this.isNextWeek(x.dueDate))).length > 0) {
      const c = _.min(cat, (x) => x.performed.length) as Activity;

      this.groupedTasks[categoryId][schedule.id] = ( c.performed.length / (this.profiles.length) ) * 100;
      return true;
    }
    if (cat.filter(x => x.performed.length === this.profiles.length)) {
      this.groupedTasks[categoryId][schedule.id] = 100;
      return true;
    }

    return false;
  }


  getMediaBimestre(categoryId: number, seq: number, schedules: Schedule[]): number {
      const schedules_ids = _.pluck(schedules, "id");

      if(!this.groupedTasks[categoryId]) {
        return 0;
      }

      const t = this.groupedTasks[categoryId].filter( (_, key) => schedules_ids.includes(key));

      const sum = t.reduce((a, b) => a + b, 0);
      const avg = (sum / t.length) || 0;

      if(!this.averages[seq]) {
        this.averages[seq] = [];
      }
      if(!this.averages[seq][categoryId]) {
        this.averages[seq][categoryId] = avg;
      }
      return avg;
  }

  getMediaTotal(seq: number): number {
    if(!this.averages[seq]) {
      return 0;
    }
    let sum = 0;
    let length = 0;

    this.averages[seq].forEach((a: any) => {
      sum += a;
      length ++;
    });

    return sum / length;
  }


  isNextWeek(dueDate: Date): boolean {
    const week = moment(dueDate).week();
    return week > this.currentWeek;
  }

  isCurrentWeek(dueDate: Date): boolean {
    const week = moment(dueDate).week();
    return week === this.currentWeek;
  }

  isPastWeek(dueDate: Date): boolean {
    const week = moment(dueDate).week();
    return week < this.currentWeek;
  }


}
