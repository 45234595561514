import { ToastrService } from 'ngx-toastr';
import { RecordService } from './../professor/service/record.service';
import { SchoolPackage } from './../shared/model/schoolPackage.model';
import { PeriodService } from './../atividades/service/period.service';
import { CategoryService } from './../shared/service/category.service';
import { ProfileSchoolPackageService } from './../shared/service/profileSchoolPackage.service';
import { AutenticacaoService } from './../login/service/autenticacao.service';
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';

import { faClipboardCheck, faClipboard, faExclamationTriangle, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { Period } from '../atividades/model/period.model';
import { RecordModel } from '../professor/model/record.model';
import { Category } from '../shared/model/category.model';
import { switchMap } from 'rxjs/operators';
import { Student } from '../aluno/model/student.model';
import { EMPTY, forkJoin, of } from 'rxjs';
import { ProfileSchoolPackage } from '../shared/model/profileSchoolPackage.model';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
  selector: 'app-turma-esforco',
  templateUrl: './turma-esforco.component.html',
  styleUrls: ['./turma-esforco.component.css']
})
export class TurmaEsforcoComponent implements OnInit {
  students: Student[] = [];
  periods: Period[] = [];
  filteredPeriods: Period[] = [];
  periodId: number = 0;
  records: RecordModel[] = [];
  categories: Category[] = [];
  chCategories: Category[] = [];
  filteredCategories: Category[] = [];
  schoolPackages: any[] = [];
  schoolPackageId: number = 0;
  profileSchoolPackages: ProfileSchoolPackage[] = [];
  filteredRecords: RecordModel[] = [];
  platformSelect: any[] = [
    {id: 57, name: 'EduFit'},
    {id: 15, name: 'Connecting English'}
  ];
  platformId: number = 57;
  packageId: number = 0;
  currentWeek: number = 0;
  isLoading = true;

  faClipboardCheck = faClipboardCheck;
  faClipboard = faClipboard;
  faExclamationTriangle = faExclamationTriangle;
  faClipboardList = faClipboardList;



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private autenticacaoService: AutenticacaoService,
    private profileSchoolPackageService: ProfileSchoolPackageService,
    private categoryService: CategoryService,
    private periodService: PeriodService,
    private recordService: RecordService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.autenticacaoService
        .getLoggedUser()
        .pipe(
          switchMap( (u) => {
            if(u?.role === 'Students') {
              this.router.navigate(['/mural']);
            }
            return forkJoin([
              this.profileSchoolPackageService.findPerProfileId(u?.profileId as number),
              this.categoryService.findAll('Hub'),
              this.periodService.findAll(),
              //this.categoryService.findAll('CH')
            ]);
          })
        )
        .subscribe( (result) => {
          this.schoolPackages = result[0]
            .filter(x => x.schoolPackage.name !== 'Galera Cult')
            .map(x => ({ id: x.schoolsPackagesId, name: x.schoolPackage.name, packageId: x.schoolPackage.packageId }));

          this.schoolPackages = _.sortBy(this.schoolPackages, (x) => parseInt(x.name) || 0);
          this.categories = result[1];
          //this.chCategories = result[3];
          this.currentWeek = (moment(new Date()).week());
          this.periods = result[2];
          this.isLoading = false;
        });
  }

  filter(): void {
    if(this.periodId == 0 || this.schoolPackageId == 0) {
      return;
    }
    this.isLoading = true;
    this.periodService
        .findPerSchoolPackageAndPeriod(this.schoolPackageId, this.periodId)
        .pipe(switchMap( (result) => {
          if(result.profiles.length === 0) {
            this.toastrService.error("Não existem alunos nesta turma");
            return EMPTY;
          }
          const profileIds = _.pluck(result.profiles, 'profileId');
          const schedules = (_.flatten(_.pluck(result.periods, 'schedules')));
          const activities = (_.uniq(_.flatten(_.pluck(schedules, 'activities'))));
          const referenceIds = (_.flatten(_.pluck(activities, 'contents'))
              .filter( x => x.platformId == this.platformId).map(x => parseInt(x.link.replace(/\D/g,''))));

          this.filteredCategories = this.categories.filter(x => activities.filter(y => y.categoryId === x.id).length > 0);

          this.packageId = result.profiles[0].schoolPackage.packageId;

          if(this.packageId < 10) {
            this.filteredCategories.push({ id: 9999, name: "Matemática 2", description: "mat2", shortName: "Mat 2", image: "", imageAlt: "" });
          }

          this.filteredCategories = _.sortBy(this.filteredCategories, (x) => x.name);
          //this.filteredCategories = this.filteredCategories.concat(this.chCategories.filter(x => activities.filter(y => y.categoryId === x.id).length > 0));

          this.profileSchoolPackages = result.profiles;
          this.filteredPeriods = result.periods;

          return this.recordService.findFilteredPerStudent({
            profileIds: profileIds,
            referenceIds: referenceIds,
            schoolsPackagesIds: [],
            platformId: this.platformId,
            year: new Date().getFullYear()
          });
        }))
        .subscribe(records => {
          this.filteredRecords = records;

          this.isLoading = false;
        });

  }

  changePeriod() {
    this.filteredPeriods = (this.periodId > 0) ?
      this.periods.filter( (x) => x.id == this.periodId) : _.clone(this.periods);
  }

  getObjEsforco(period: Period, profileId: number, categoryId: number): string {

    const activities = (_.uniq(_.flatten(_.pluck(period.schedules, 'activities'))));

    let category_activities = (categoryId === 9999) ?
      activities?.filter(x => x.categoryId === 6) :
      activities?.filter(x => x.categoryId === categoryId);

    const period_numbers_mat = period.schedules.map( x => parseFloat(x.name.replace( /^\D+/g, '')));
    const period_numbers_mat_2 = period_numbers_mat.map( x => x + 10);

    if (category_activities && this.packageId < 10 && (categoryId === 6 || categoryId === 9999) ) {
      const numeracoes = _.uniq(category_activities.map((x) => parseFloat(x.title.replace( /^\D+/g, '')))).sort( (a, b) => a - b);

      if(categoryId === 6) {
        category_activities = category_activities.filter((x) => period_numbers_mat.includes(parseFloat(x.title.replace( /^\D+/g, ''))));
        //category_activities = category_activities.filter((x) => parseInt(x.title.replace( /^\D+/g, '')) ===  numeracoes[0]);
      } else {
        category_activities = category_activities.filter((x) => period_numbers_mat_2.includes(parseFloat(x.title.replace(/^\D+/g, ''))));
        //category_activities = category_activities.filter((x) => parseInt(x.title.replace( /^\D+/g, '')) ===  numeracoes[numeracoes.length - 1]);
      }
    }

    category_activities = category_activities.filter(x => this.isPastWeekDate(x.dueDate));

    if(!category_activities || category_activities.length == 0) {
      return '-';
    }

    const referenceIds = _.uniq( (_.flatten(_.pluck(category_activities, 'contents'))
      .filter( x => x.platformId == this.platformId).map(x => parseInt(x.link.replace(/\D/g,'')))) );

    if(!referenceIds || referenceIds.length == 0) {
      return '-';
    }

    const performed = this.filteredRecords.filter(x => referenceIds.includes(x.referenceId) && x.profileId === profileId);
    let esforco = ( ( performed.length / referenceIds.length) * 100 );

    return esforco > 100 ? '100%' : esforco.toFixed(2) + '%';

    //return ( ( performed.length / referenceIds.length) * 100 ).toFixed(2) + '%';
  }

  getObjEsforcoTurma(period: Period) {
    const activities = (_.uniq(_.flatten(_.pluck(period.schedules, 'activities'))));

    if(!activities || activities.length == 0) {
      return '-';
    }

    const referenceIds = _.uniq( (_.flatten(_.pluck(activities, 'contents'))
    .filter( x => x.platformId == this.platformId).map(x => parseInt(x.link.replace(/\D/g,'')))) );

    if(!referenceIds || referenceIds.length == 0) {
      return '-';
    }

    const performed = this.filteredRecords.filter(x => referenceIds.includes(x.referenceId));

    if(this.profileSchoolPackages.length === 0) {
      return '-';
    }


    return ( ( performed.length / ( referenceIds.length * this.profileSchoolPackages.length ) ) * 100 ).toFixed(2) + '%';

  }

  isPastWeekDate(dueDate: Date): boolean {
    const week = moment(dueDate).week();
    return week <= this.currentWeek
  }

}
