export const environment = {
  production: false,
  // API_PATH: 'https://localhost:44308/api/',
  // TOKEN_PATH: 'https://localhost:44308/Token',
  API_PATH: 'https://learningsystemimport.azurewebsites.net/api/',
  TOKEN_PATH: 'https://learningsystemimport.azurewebsites.net/Token',
  AUTH_TOKEN_KEY: 'authToken',
  AUTH_USER_KEY: 'authUser',
  VERSION: '2.8.12'
};
